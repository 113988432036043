import React, { useState } from 'react';
import { Treebeard } from 'react-treebeard';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer';
import NodePopupMenu from '../menu/NodePopupMenu';
import { saveDiagramAsHTML, exportAsJson } from './DiagramIO';
import DefaultNode from './nodes/DefaultNode';
import OrNode from './nodes/OrNode';
import AndNode from './nodes/AndNode';
import NoomNode from './nodes/NoomNode';
import LogicNode from './nodes/LogicNode';
import ScheduleNode from './nodes/ScheduleNode';

import { addNode } from './node_actions/AddNode';
import { setUpstreamDownstream } from './node_actions/EditNode';

import { resetNodesAndEdges } from '../utils/nodeActionsUtils';
import { cacheCurrentDiagram, loadCachedDiagram } from './node_actions/CacheActions';
import { handleAddFolder, handleAddView, deleteNodeFromTree } from './node_actions/SideMenuActions';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const gridSize = 20;

const nodeTypes = {
  default: DefaultNode,
  orNode: OrNode,
  andNode: AndNode,
  noomNode: NoomNode,
  logicNode: LogicNode,
  scheduleNode: ScheduleNode,
};

const NodeEditor = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeId, setNodeId] = useState(1);
  const [currentNode, setCurrentNode] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [isSimplePopupOpen, setSimplePopupOpen] = useState(false);
  const [cachedDiagrams, setCachedDiagrams] = useState({});
  const [loadCachedDiagramName, setLoadCachedDiagramName] = useState('');

  // Tree structure for the folder
  const [data, setData] = useState({
    name: 'Root',
    toggled: true,
    children: [
      { name: 'Folder 1', children: [{ name: 'Block Structure 1' }] },
      { name: 'Folder 2', children: [{ name: 'Block Structure 2' }] }
    ]
  });

    // Handle view selection from the folder tree
    const handleViewSelect = (viewName) => {
      if (viewName === currentView) return; // Do nothing if selecting the same view
  
      cacheCurrentDiagram(currentView, nodes, edges, setCachedDiagrams); // Cache the current view
      setCurrentView(viewName); // Switch to the new view
      loadCachedDiagram(viewName, cachedDiagrams, setNodes, setEdges, resetNodesAndEdges, setNodeId); // Load the selected view's diagram
    };

  // Selected folder for adding subfolders/views
  const [selectedFolder, setSelectedFolder] = useState(null);

  // Function to handle folder selection
  const handleToggle = (node, toggled) => {
    if (node.children) {
      node.toggled = toggled;
    }
    handleViewSelect(node.name);
    setSelectedFolder(node); // Set the selected folder
    setData({ ...data });
  };

  // Function to add a folder
  const handleAddFolder = () => {
    if (selectedFolder) {
      selectedFolder.children = selectedFolder.children || [];
      selectedFolder.children.push({ name: `New Folder ${selectedFolder.children.length + 1}` });
      setData({ ...data });
    }
  };

  // Function to add a view (block structure)
  const handleAddView = () => {
    if (selectedFolder) {
      selectedFolder.children = selectedFolder.children || [];
      selectedFolder.children.push({ name: `New View ${selectedFolder.children.length + 1}` });
      setData({ ...data });
    }
  };


  // Recursive function to delete a node
  const deleteNodeFromTree = (node, targetNodeName) => {
    if (!node.children) return;

    // Filter out the child that matches the target name
    node.children = node.children.filter((child) => child.name !== targetNodeName);

    // Recursively check all children
    node.children.forEach((child) => deleteNodeFromTree(child, targetNodeName));
  };

  // Function to handle delete action
  const handleDelete = (nodeName) => {
    deleteNodeFromTree(data, nodeName);
    setData({ ...data });
  };
  
  const handleNodeDoubleClick = (event, node) => {
    event.preventDefault();
    setCurrentNode(node);
    setSimplePopupOpen(true);
  };

  const handleNodeAdd = (type, label) => {
    addNode(type, label, nodes, setNodes, nodeId, setNodeId);
  };

  const handleReset = () => {
    resetNodesAndEdges(setNodes, setEdges, setNodeId);
  };

  const handleEdgeConnect = (params) => {
    setEdges((eds) => {
      const newEdges = addEdge(params, eds);
      setUpstreamDownstream(params.source, params.target, nodes, setNodes);
      return newEdges;
    });
  };

  return (
    <div id="node-editor" style={{ display: 'flex', height: '100vh' }}>
      {/* Side Menu */}
      <div style={{ width: '300px', borderRight: '1px solid #ccc', padding: '10px', backgroundColor: '#f5f5f5' }}>
        <button onClick={handleAddFolder} style={{ marginBottom: '10px', width: '100%' }}>Add Folder</button>
        <button onClick={handleAddView} style={{ marginBottom: '10px', width: '100%' }}>Add View to {selectedFolder?.name || 'Folder'}</button>
        <button onClick={() => handleDelete(selectedFolder?.name)} style={{ marginBottom: '10px', width: '100%' }}>Delete {selectedFolder?.name}</button>
        <Treebeard data={data} onToggle={handleToggle} />
      </div>
      <div style={{ flexGrow: 1, padding: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <button
            onClick={() => {
              handleNodeAdd('default', `Node ${nodeId}`);
            }}
            style={{ marginRight: '10px' }}
          >
            Add Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('orNode', 'OR');
            }}
            style={{ marginRight: '10px' }}
          >
            Add OR Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('andNode', 'AND');
            }}
            style={{ marginRight: '10px' }}
          >
            Add AND Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('noomNode', 'NooM');
            }}
            style={{ marginRight: '10px' }}
          >
            Add NooM Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('logicNode', 'Logic');
            }}
            style={{ marginRight: '10px' }}
          >
            Add Logic Node
          </button>
          <button
            onClick={() => {
              handleNodeAdd('scheduleNode', 'Schedule');
            }}
            style={{ marginRight: '10px' }}
          >
            Add Schedule Node
          </button>
          <button onClick={handleReset} style={{ marginRight: '10px' }}>
            Reset
          </button>
          <button onClick={() => saveDiagramAsHTML(nodes, edges)} style={{ marginRight: '10px' }}>
            Save Diagram as HTML
          </button>
          <button onClick={() => exportAsJson(nodes, edges)} style={{ marginRight: '10px' }}>
            Export as JSON
          </button>
        </div>
        <div style={{ width: '100%', height: 'calc(100% - 40px)', position: 'relative' }}>
          <ReactFlowProvider>
            <div style={{ width: '100%', height: '100%' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={handleEdgeConnect}
                nodeTypes={nodeTypes}
                fitView
                onNodeDoubleClick={handleNodeDoubleClick}
                snapToGrid={true}
                snapGrid={[gridSize, gridSize]}
                defaultEdgeOptions={{ type: 'straight' }}
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
          <NodePopupMenu open={isSimplePopupOpen} handleClose={() => setSimplePopupOpen(false)} />
        </div>
      </div>
    </div>
  );
};

export default NodeEditor;