import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    // Load user (including token and id) from localStorage when the component mounts
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            console.log("Loaded user from localStorage:", parsedUser);  // Debugging log
            setUser(parsedUser); // Set user state from localStorage
        }
    }, []);

    // Update localStorage whenever the user state changes
    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user)); // Store user in localStorage
        } else {
            localStorage.removeItem('user'); // Clear localStorage on logout
        }
    }, [user]);

    // Function to log out the user (clears user data and token)
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');  // Clear user from localStorage
    };

    // Handle login to ensure user id and token are stored properly
    const handleLogin = async (loginData) => {
        try {
            const response = await axios.post('${backendUrl}/login', loginData);
            const { id, token, username } = response.data;  // Assuming your backend returns these fields
            console.log("Login successful", { id, token, username });  // Debugging log
            const newUser = { id, token, username };
            setUser(newUser);  // Save user id, token, and username
        } catch (error) {
            console.error("Login failed", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, logout, handleLogin }}>
            {children}
        </UserContext.Provider>
    );
};