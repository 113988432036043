import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';  // Import the UserContext

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);  // Get setUser from UserContext
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.post(`${databaseUrl}/login`, {
                username,
                password,
            });

            // Handle login success
            const userData = response.data;
            console.log('Login details:', userData);

            // Store user and token in localStorage, including user_id
            localStorage.setItem('user', JSON.stringify({
                id: userData.user_id,  // Store the user_id
                username: userData.username,
                access_level: userData.access_level,
                is_admin: userData.is_admin,
                token: userData.token,  // Save the JWT token
            }));

            // Store the user in UserContext, including user_id
            setUser({
                id: userData.user_id,  // Include user_id in UserContext
                username: userData.username,
                access_level: userData.access_level,
                is_admin: userData.is_admin,
                token: userData.token,  // Include token in the context
            });

            // Redirect after successful login
            navigate('/');
        } catch (err) {
            setError('Invalid username or password');
            localStorage.removeItem('user');
            setUser(null);
            navigate('/login');
        }
    };

    return (
        <div>
            <h2>Login</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginForm;