import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import MenuBar from './components/MenuBar';
import Inputs from './pages/Inputs';
import SolveWeibullPage from './pages/SolveWeibull';
import NodeEditorPage from './pages/NodeEditor';

// Forms
import CreateUserForm from './connections/CreateUserForm';
import CreateScheduledEventForm from './connections/CreateScheduledEventForm';
import CreateConditionalEventForm from './connections/CreateConditionalEvent';
import CreateUnitForm from './connections/CreateUnitForm';
import CreatePartForm from './connections/CreatePartForm'; 
import CreateProjectForm from './connections/CreateProjectForm';

// Authentication
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import ProtectedRoute from './components/ProtectedRoute';
import AdminDashboard from './admin/AdminDashboard';
import ManageUsers from './admin/ManageUsers';
import AssignAccessLevel from './admin/AssignAccessLevel';
import PublicRoute from './components/PublicRoute';

// View Components
import ViewProjects from './components/ViewProjects'; 
import ViewUnits from './components/ViewUnits';
import ViewParts from './components/ViewParts';
import ViewConditionalEvents from './components/ViewConditionalEvents'; // New Conditional Events component
import ViewScheduledEvents from './components/ViewScheduledEvents'; // New Schedule Events component

function App() {
    return (
        <UserProvider> 
            <Router>
                <MenuBar />
                <Routes>
                    <Route path="/" element={<Inputs />} />
                    <Route path="/solve" element={<SolveWeibullPage />} />
                    <Route path="/node-editor" element={<NodeEditorPage />} />
                
                    {/* Admin Routes */}
                    <Route path="/admin" element={<AdminDashboard />}>
                        <Route path="manage-users" element={<ManageUsers />} />
                        <Route path="assign-access-level" element={<AssignAccessLevel />} />
                    </Route>

                    {/* Use PublicRoute for /login */}
                    <Route 
                        path="/login" 
                        element={
                            <PublicRoute>
                                <LoginForm />
                            </PublicRoute>
                        } 
                    />

                    {/* Use PublicRoute for /register */}
                    <Route 
                        path="/register" 
                        element={
                            <PublicRoute>
                                <RegisterForm />
                            </PublicRoute>
                        } 
                    />

                    {/* Protected Routes */}
                    <Route 
                        path="/create-user" 
                        element={
                            <ProtectedRoute>
                                <CreateUserForm />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/create-schedule-event" 
                        element={
                            <ProtectedRoute>
                                <CreateScheduledEventForm />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/create-unit" 
                        element={
                            <ProtectedRoute>
                                <CreateUnitForm />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/create-part" 
                        element={
                            <ProtectedRoute>
                                <CreatePartForm />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/create-project" 
                        element={
                            <ProtectedRoute>
                                <CreateProjectForm />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Protected View Routes */}
                    <Route 
                        path="/view-projects" 
                        element={
                            <ProtectedRoute>
                                <ViewProjects />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/view-units/:projectId"  
                        element={
                            <ProtectedRoute>
                                <ViewUnits />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/view-parts/:projectId"  
                        element={
                            <ProtectedRoute>
                                <ViewParts />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/view-conditional-events/:projectId"  
                        element={
                            <ProtectedRoute>
                                <ViewConditionalEvents />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/view-schedule-events/:projectId"  
                        element={
                            <ProtectedRoute>
                                <ViewScheduledEvents />
                            </ProtectedRoute>
                        } 
                    />
                </Routes>
            </Router>
        </UserProvider>
    );
}

export default App;
