import React, { useState } from "react";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const CreatePartForm = () => {
  const [unitId, setUnitId] = useState("");
  const [shieldingDistribution, setShieldingDistribution] = useState("");
  const [affectedParts, setAffectedParts] = useState("");
  const [error, setError] = useState("");

  const parseInput = (input) => {
    try {
      return input.split(',').map(Number).filter(num => !isNaN(num));
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data.error : "Failed to connect to the server.";
      console.error("There was an error creating the part!", errorMessage);
      alert(errorMessage);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const shielding = parseInput(shieldingDistribution);
      const affected = parseInput(affectedParts);

      const response = await axios.post(`${databaseUrl}/create_part`, {
        unit_id: Number(unitId),
        shielding_distribution: shielding,
        affected_parts: affected,
      });

      alert("Part created successfully");
    } catch (error) {
      setError(error.response ? error.response.data.error : error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        <label>Unit ID:</label>
        <input type="number" value={unitId} onChange={(e) => setUnitId(e.target.value)} />
      </div>
      <div>
        <label>Shielding Distribution (comma separated):</label>
        <input type="text" value={shieldingDistribution} onChange={(e) => setShieldingDistribution(e.target.value)} />
      </div>
      <div>
        <label>Affected Parts (comma separated IDs):</label>
        <input type="text" value={affectedParts} onChange={(e) => setAffectedParts(e.target.value)} />
      </div>
      <button type="submit">Create Part</button>
    </form>
  );
};

export default CreatePartForm;