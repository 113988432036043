import React, { useState } from 'react';
import axios from 'axios';

const AssignAccessLevel = () => {
    const [userId, setUserId] = useState('');
    const [accessLevel, setAccessLevel] = useState('free-tier');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/admin/assign_access_level', {
                user_id: userId,
                access_level: accessLevel
            });
            alert('Access level updated successfully');
        } catch (error) {
            alert('Failed to update access level');
        }
    };

    return (
        <div>
            <h2>Assign Access Level</h2>
            <form onSubmit={handleSubmit}>
                <label>User ID:</label>
                <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} />
                <label>Access Level:</label>
                <select value={accessLevel} onChange={(e) => setAccessLevel(e.target.value)}>
                    <option value="free-tier">Free Tier</option>
                    <option value="elevated-tier">Elevated Tier</option>
                </select>
                <button type="submit">Assign Access Level</button>
            </form>
        </div>
    );
};

export default AssignAccessLevel;