import React from 'react';
import AdminMenu from './AdminMenu';
import { Outlet } from 'react-router-dom'; // Outlet for nested routes

const AdminDashboard = () => {
    return (
        <div style={dashboardStyle}>
            <h1>Admin Dashboard</h1>
            <AdminMenu />
            <div style={contentStyle}>
                <Outlet /> {/* This is where nested components like ManageUsers and AssignAccessLevel will be rendered */}
            </div>
        </div>
    );
};

const dashboardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
};

const contentStyle = {
    marginTop: '20px',
    width: '100%',
};

export default AdminDashboard;