import React, { useState } from "react";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const CreateScheduleEventForm = () => {
  const [projectId, setProjectId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/create_schedule_event`, {
        project_id: projectId,
        start_time: parseFloat(startTime),
        stop_time: parseFloat(stopTime),
      });
      alert("Schedule Event created successfully");
    } catch (error) {
      console.error("There was an error creating the schedule event!", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Project ID:</label>
        <input type="number" value={projectId} onChange={(e) => setProjectId(e.target.value)} />
      </div>
      <div>
        <label>Start Time (seconds):</label>
        <input type="text" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
      </div>
      <div>
        <label>Stop Time (seconds):</label>
        <input type="text" value={stopTime} onChange={(e) => setStopTime(e.target.value)} />
      </div>
      <button type="submit">Create Schedule Event</button>
    </form>
  );
};

export default CreateScheduleEventForm;