import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';  // Import the UserContext

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const ViewProjects = () => {
    const { user } = useContext(UserContext);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${databaseUrl}/get_user_projects`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`  // Send the JWT token
                    }
                });
                setProjects(response.data);  // Set the fetched projects
            } catch (err) {
                console.error('Failed to fetch projects', err);
            }
        };

        if (user) {
            fetchProjects();
        }
    }, [user]);

    // Handle project selection
    const selectProject = (project) => {
        sessionStorage.setItem('selectedProject', JSON.stringify(project));  // Store the selected project in sessionStorage
        alert(`Project ${project.name} selected!`);
        window.location.reload();  // Reload the page to reflect the selected project
    };

    // Handle project deletion
    const handleDeleteProject = async (projectId) => {
        try {
            // Send a request to delete the project
            await axios.delete(`http://${backendUrl}:3001/delete_project/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`  // Send the JWT token
                }
            });
            // Update the project list after deletion
            setProjects(projects.filter((project) => project.id !== projectId));
            alert('Project deleted successfully');
        } catch (error) {
            console.error('Failed to delete project', error);
        }
    };

    return (
        <div>
            <h2>Your Projects</h2>
            <ul>
                {projects.map((project) => (
                    <li key={project.id}>
                        <strong>{project.name}</strong>: {project.description} {' '}
                        <button onClick={() => selectProject(project)}>Select</button>
                        <button onClick={() => handleDeleteProject(project.id)}>Delete</button> {/* Delete button */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ViewProjects;