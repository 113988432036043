import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import { useParams } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const ViewParts = () => {
    const { user } = useContext(UserContext);
    const [parts, setParts] = useState([]);
    const [units, setUnits] = useState([]);
    const [newPart, setNewPart] = useState({
        unit_id: '',
        part_name: '',
        shielding_distribution: '',
        affected_parts: '',
    });

    const { projectId } = useParams();

    useEffect(() => {
        if (!projectId || !user?.token) {
            console.error("Missing projectId or user token");
            return;
        }

        const fetchUnits = async () => {
            try {
                const response = await axios.post(`${databaseUrl}/get_units_in_project`, {
                    project_id: projectId,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setUnits(response.data);
            } catch (error) {
                console.error('Error fetching units:', error);
            }
        };

        const fetchParts = async () => {
            try {
                const response = await axios.post(`${backendUrl}/get_parts_in_project`, {
                    project_id: projectId,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                const partsData = Array.isArray(response.data) ? response.data : [];  // Ensure parts is an array
                setParts(partsData);
            } catch (error) {
                console.error('Error fetching parts:', error);
                setParts([]);  // Set to empty array in case of error
            }
        };

        fetchUnits();
        fetchParts();
    }, [projectId, user?.token]);

    const handleAddPart = async () => {
        if (!projectId || !user?.token) {
            console.error('Missing projectId or user token');
            return;
        }

        try {
            const formattedAffectedParts = newPart.affected_parts
                ? [parseInt(newPart.affected_parts)]
                : [];

            const response = await axios.post(`${backendUrl}/create_part`, {
                project_id: projectId,
                ...newPart,
                affected_parts: formattedAffectedParts,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const updatedPartsResponse = await axios.post(`${backendUrl}/get_parts_in_project`, {
                project_id: projectId,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const partsData = Array.isArray(updatedPartsResponse.data) ? updatedPartsResponse.data : [];
            setParts(partsData);  // Always set parts as an array
            setNewPart({ unit_id: '', part_name: '', shielding_distribution: '', affected_parts: '' });
        } catch (error) {
            console.error('There was an error adding the part!', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPart({ ...newPart, [name]: value });
    };

    return (
        <div>
            <h3>Parts in Project {projectId}</h3>
            {parts.length === 0 ? (
                <p>No parts available for this project.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Part ID</th>
                            <th>Part Name</th>
                            <th>Unit Name</th>
                            <th>Shielding Distribution</th>
                            <th>Affected Parts</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parts.map((part) => (
                            <tr key={part[0]}>  {/* Ensure the key is unique */}
                                <td>{part[0]}</td>
                                <td>{part[5]}</td>
                                <td>{(units.find(unit => unit.id === part[1])?.unit_name) || 'N/A'}</td>
                                <td>{Array.isArray(part[2]) ? part[2].join(', ') : 'No shielding distribution'}</td>
                                <td>{part[3]?.join(', ') || 'None'}</td>
                                <td>{part[4]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <h4>Add a New Part</h4>
            <table>
                <tbody>
                    <tr>
                        <td>New</td>
                        <td>
                            <input
                                type="text"
                                name="part_name"
                                value={newPart.part_name}
                                onChange={handleInputChange}
                                placeholder="Part Name"
                                required
                            />
                        </td>
                        <td>
                            <select
                                name="unit_id"
                                value={newPart.unit_id}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Unit</option>
                                {units.map((unit) => (
                                    <option key={unit.id} value={unit.id}>
                                        {unit.unit_name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <input
                                type="text"
                                name="shielding_distribution"
                                value={newPart.shielding_distribution}
                                onChange={handleInputChange}
                                placeholder="Shielding Distribution"
                                required
                            />
                        </td>
                        <td>
                            <select
                                name="affected_parts"
                                value={newPart.affected_parts}
                                onChange={handleInputChange}
                            >
                                <option value="">None</option>
                                {parts.map((part) => (
                                    <option key={part[0]} value={part[0]}>
                                        {part[5]}  {/* Display the part_name */}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>Auto-generated</td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleAddPart}>+ Add Part</button>
        </div>
    );
};

export default ViewParts;