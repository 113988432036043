// src/components/PlotWeibull.js
import React, { useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const PlotWeibull = ({ a, x0, w, s }) => {
    const [plotData, setPlotData] = useState(null);

    const handlePlot = async () => {
        try {
            const xs = Array.from({ length: 121 }, (_, i) => i); // Generate x values from 0 to 120
            const ysPromises = xs.map((x) =>
                axios.post(`${backendUrl}/process`, {
                    x,
                    a: parseFloat(a),
                    x0: parseFloat(x0),
                    w: parseFloat(w),
                    s: parseFloat(s),
                }).then(response => response.data.result)
            );
            
            const ys = await Promise.all(ysPromises);

            console.log('X values:', xs);
            console.log('Y values:', ys); // Log Y values to see if they are correctly fetched

            setPlotData({
                x: xs,
                y: ys,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
            });
        } catch (error) {
            console.error('Error in handlePlot:', error);
        }
    };

    return (
        <div>
            <button type="button" onClick={handlePlot}>Plot</button>
            {plotData && (
                <div>
                    <h2>Weibull Function Plot</h2>
                    <Plot
                        data={[plotData]}
                        layout={{
                            title: 'Weibull Function',
                            xaxis: { title: 'x' },
                            yaxis: { title: 'f(x)', type: 'log' }, // Set y-axis to log scale
                            autosize: true
                        }}
                        style={{ width: "100%", height: "500px" }}
                    />
                </div>
            )}
        </div>
    );
};

export default PlotWeibull; // Make sure to use default export