import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import { useParams } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const ViewUnits = () => {
    const { user } = useContext(UserContext);
    const [units, setUnits] = useState([]);
    const [editingUnit, setEditingUnit] = useState(null);  // Track the unit being edited
    const [newUnit, setNewUnit] = useState({
        unit_name: '',
        proton_upset_cross_sections: '',
        heavy_ion_upset_cross_sections: '',
        reset_time: '',
    });
    const { projectId } = useParams();

    useEffect(() => {
        if (!projectId || !user?.token) {
            console.error("Missing projectId or user token");
            return;
        }

        const fetchUnits = async () => {
            try {
                const response = await axios.post(`${databaseUrl}/get_units_in_project`, {
                    project_id: projectId,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setUnits(response.data);
            } catch (error) {
                console.error('Error fetching units:', error);
            }
        };

        fetchUnits();
    }, [projectId, user?.token]);

    const handleAddUnit = async () => {
        if (!projectId || !user?.token) {
            console.error('Missing projectId or user token');
            return;
        }

        try {
            const response = await axios.post('http://${backendUrl}:3001/create_unit', {
                project_id: projectId,
                ...newUnit,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setUnits([...units, response.data]);
            setNewUnit({
                unit_name: '',
                proton_upset_cross_sections: '',
                heavy_ion_upset_cross_sections: '',
                reset_time: '',
            });
        } catch (error) {
            console.error('There was an error adding the unit!', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUnit({ ...newUnit, [name]: value });
    };

    const handleEditUnit = async (unitId, fieldName, newValue) => {
        try {
            const updatedUnit = { ...units.find(unit => unit.id === unitId), [fieldName]: newValue };
            await axios.patch(`http://${backendUrl}:3001/update_unit/${unitId}`, updatedUnit, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setUnits(units.map(unit => unit.id === unitId ? updatedUnit : unit));
            setEditingUnit(null);
        } catch (error) {
            console.error('There was an error updating the unit!', error);
        }
    };

    const handleDoubleClick = (unitId, fieldName, currentValue) => {
        setEditingUnit({ unitId, fieldName, currentValue });
    };

    const handleBlur = (unitId, fieldName, newValue) => {
        if (editingUnit && newValue !== editingUnit.currentValue) {
            handleEditUnit(unitId, fieldName, newValue);
        } else {
            setEditingUnit(null);
        }
    };

    const handleKeyDown = (e, unitId, fieldName, newValue) => {
        if (e.key === 'Enter') {
            handleBlur(unitId, fieldName, newValue);
        }
    };

    return (
        <div>
            <h3>Units in Project {projectId}</h3>
            {units.length === 0 ? (
                <p>No units available for this project.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Unit ID</th>
                            <th>Unit Name</th>
                            <th>Proton Upset Cross Sections</th>
                            <th>Heavy Ion Upset Cross Sections</th>
                            <th>Reset Time</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {units.map((unit) => (
                            <tr key={unit.id}>
                                <td>{unit.id}</td>
                                <td>
                                    {editingUnit?.unitId === unit.id && editingUnit.fieldName === 'unit_name' ? (
                                        <input
                                            type="text"
                                            defaultValue={unit.unit_name}
                                            onBlur={(e) => handleBlur(unit.id, 'unit_name', e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(e, unit.id, 'unit_name', e.target.value)}
                                        />
                                    ) : (
                                        <span onDoubleClick={() => handleDoubleClick(unit.id, 'unit_name', unit.unit_name)}>
                                            {unit.unit_name || 'N/A'}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {editingUnit?.unitId === unit.id && editingUnit.fieldName === 'proton_upset_cross_sections' ? (
                                        <input
                                            type="text"
                                            defaultValue={unit.proton_upset_cross_sections}
                                            onBlur={(e) => handleBlur(unit.id, 'proton_upset_cross_sections', e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(e, unit.id, 'proton_upset_cross_sections', e.target.value)}
                                        />
                                    ) : (
                                        <span onDoubleClick={() => handleDoubleClick(unit.id, 'proton_upset_cross_sections', unit.proton_upset_cross_sections)}>
                                            {unit.proton_upset_cross_sections}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {editingUnit?.unitId === unit.id && editingUnit.fieldName === 'heavy_ion_upset_cross_sections' ? (
                                        <input
                                            type="text"
                                            defaultValue={unit.heavy_ion_upset_cross_sections}
                                            onBlur={(e) => handleBlur(unit.id, 'heavy_ion_upset_cross_sections', e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(e, unit.id, 'heavy_ion_upset_cross_sections', e.target.value)}
                                        />
                                    ) : (
                                        <span onDoubleClick={() => handleDoubleClick(unit.id, 'heavy_ion_upset_cross_sections', unit.heavy_ion_upset_cross_sections)}>
                                            {unit.heavy_ion_upset_cross_sections}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {editingUnit?.unitId === unit.id && editingUnit.fieldName === 'reset_time' ? (
                                        <input
                                            type="text"
                                            defaultValue={unit.reset_time}
                                            onBlur={(e) => handleBlur(unit.id, 'reset_time', e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(e, unit.id, 'reset_time', e.target.value)}
                                        />
                                    ) : (
                                        <span onDoubleClick={() => handleDoubleClick(unit.id, 'reset_time', unit.reset_time)}>
                                            {unit.reset_time}
                                        </span>
                                    )}
                                </td>
                                <td>{unit.created_at || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <h4>Add a New Unit</h4>
            <table>
                <tbody>
                    <tr>
                        <td>New</td>
                        <td>
                            <input
                                type="text"
                                name="unit_name"
                                value={newUnit.unit_name}
                                onChange={handleInputChange}
                                placeholder="Unit Name"
                                required
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="proton_upset_cross_sections"
                                value={newUnit.proton_upset_cross_sections}
                                onChange={handleInputChange}
                                placeholder="Proton Upset"
                                required
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="heavy_ion_upset_cross_sections"
                                value={newUnit.heavy_ion_upset_cross_sections}
                                onChange={handleInputChange}
                                placeholder="Heavy Ion Upset"
                                required
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="reset_time"
                                value={newUnit.reset_time}
                                onChange={handleInputChange}
                                placeholder="Reset Time"
                                required
                            />
                        </td>
                        <td>Auto-generated</td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleAddUnit}>+ Add Unit</button>
        </div>
    );
};

export default ViewUnits;