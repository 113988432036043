import React, { useState } from 'react';
import axios from 'axios';
import PlotWeibull from '../components/PlotWeibull';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

function Inputs() {
    const [x, setX] = useState('');
    const [a, setA] = useState('');
    const [x0, setX0] = useState('');
    const [w, setW] = useState('');
    const [s, setS] = useState('');
    const [result, setResult] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${backendUrl}/process`, {
                x: parseFloat(x),
                a: parseFloat(a),
                x0: parseFloat(x0),
                w: parseFloat(w),
                s: parseFloat(s),
            });
            console.log('Response from handleSubmit:', response.data);
            setResult(response.data);
        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };

    return (
        <div>
            <h1>Weibull Function Calculator</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    x (Independent Variable):
                    <input
                        type="number"
                        value={x}
                        onChange={(e) => setX(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    a (Saturation Cross Section (cm^-2)):
                    <input
                        type="number"
                        value={a}
                        onChange={(e) => setA(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    x0 (Onset LET (MeV-cm^2/mg)):
                    <input
                        type="number"
                        value={x0}
                        onChange={(e) => setX0(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    w (Width Parameter (MeV-cm^2/mg)):
                    <input
                        type="number"
                        value={w}
                        onChange={(e) => setW(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    s (Shape Parameter):
                    <input
                        type="number"
                        value={s}
                        onChange={(e) => setS(e.target.value)}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>

            {result && (
                <div>
                    <h2>Result:</h2>
                    <p>Expected Cross Section: {result.result.toExponential(3)}</p>
                </div>
            )}

            <PlotWeibull a={a} x0={x0} w={w} s={s} />
        </div>
    );
}

export default Inputs;