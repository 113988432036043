import React from 'react';
import SolveWeibull from '../components/SolveWeibull';

function SolveWeibullPage() {
    return (
        <div>
            <h1>Solve Weibull</h1>
            <SolveWeibull />
        </div>
    );
}

export default SolveWeibullPage;