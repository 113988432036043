import React from 'react';
import NodeEditor from '../components/NodeEditor';

function NodeEditorPage() {
    return (
        <div>
            <h1>Node Editor</h1>
            <NodeEditor />
        </div>
    );
}

export default NodeEditorPage;