import React, { useState } from "react";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const CreateUnitForm = ({ projectId }) => {
  const [unitName, setUnitName] = useState("");  // New state for unit name
  const [protonCrossSections, setProtonCrossSections] = useState("");
  const [heavyIonCrossSections, setHeavyIonCrossSections] = useState("");
  const [resetTime, setResetTime] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/create_unit`, {
        project_id: projectId,
        unit_name: unitName,  // Include unit name in the request
        proton_upset_cross_sections: protonCrossSections.split(',').map(Number),
        heavy_ion_upset_cross_sections: heavyIonCrossSections.split(',').map(Number),
        reset_time: parseFloat(resetTime),
      });
      alert("Unit created successfully");
      setUnitName("");  // Reset unit name field
    } catch (error) {
      console.error("There was an error creating the unit!", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Unit Name:</label>  {/* New field for unit name */}
        <input
          type="text"
          value={unitName}
          onChange={(e) => setUnitName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Proton Upset Cross Sections (comma separated):</label>
        <input
          type="text"
          value={protonCrossSections}
          onChange={(e) => setProtonCrossSections(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Heavy Ion Upset Cross Sections (comma separated):</label>
        <input
          type="text"
          value={heavyIonCrossSections}
          onChange={(e) => setHeavyIonCrossSections(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Reset Time (seconds):</label>
        <input
          type="text"
          value={resetTime}
          onChange={(e) => setResetTime(e.target.value)}
          required
        />
      </div>
      <button type="submit">Create Unit</button>
    </form>
  );
};

export default CreateUnitForm;