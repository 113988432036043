import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const ViewConditionalEvents = () => {
    const { projectId } = useParams();  // Get projectId from URL parameters
    const { user } = useContext(UserContext);  // Access the user context
    const [conditionalEvents, setConditionalEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({ condition: '', boolean: '' });
    const [editEventId, setEditEventId] = useState(null);
    const [editEventData, setEditEventData] = useState({ condition: '', boolean: '' });

    // Fetch conditional events when component mounts
    useEffect(() => {
        const fetchConditionalEvents = async () => {
            try {
                const response = await axios.post(`${databaseUrl}/simulation/get_conditional_events_in_project`, {
                    project_id: projectId,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,  // Send token in headers
                    },
                });
                setConditionalEvents(response.data);
            } catch (error) {
                console.error('Error fetching conditional events:', error);
            }
        };

        if (user && projectId) {
            fetchConditionalEvents();  // Fetch conditional events when component mounts
        }
    }, [user, projectId]);

    // Handle adding a new conditional event
    const handleAddEvent = async () => {
        if (!projectId || !user?.token) {
            console.error('Missing projectId or user token');
            return;
        }

        try {
            const response = await axios.post(`${backendUrl}/simulation/create_conditional_event`, {
                project_id: projectId,
                condition: newEvent.condition,
                boolean: newEvent.boolean === 'true',  // Convert string to boolean
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setConditionalEvents([...conditionalEvents, response.data]);
            setNewEvent({ condition: '', boolean: '' });  // Reset the new event form
        } catch (error) {
            console.error('There was an error adding the conditional event!', error);
        }
    };

    // Handle input change for new event
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };

    // Handle event deletion
    const handleDelete = async (eventId) => {
        try {
            await axios.delete(`http://${backendUrl}:3001/simulation/delete_conditional_event/${eventId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,  // Send token in headers
                },
            });
            // Filter out the deleted event from the list
            setConditionalEvents(conditionalEvents.filter(event => event.id !== eventId));
        } catch (error) {
            console.error('Error deleting conditional event:', error);
        }
    };

    // Handle event editing
    const handleEdit = (event) => {
        setEditEventId(event.id);
        setEditEventData({
            condition: event.condition,
            boolean: event.boolean,
        });
    };

    // Handle event update submission
    const handleUpdate = async () => {
        try {
            await axios.patch(`http://${backendUrl}:3001/simulation/update_conditional_event/${editEventId}`, {
                condition: editEventData.condition,
                boolean: editEventData.boolean === 'true',  // Convert string to boolean
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,  // Send token in headers
                },
            });
            setConditionalEvents(conditionalEvents.map(event => (
                event.id === editEventId ? { ...event, ...editEventData } : event
            )));
            setEditEventId(null);  // Reset edit mode
        } catch (error) {
            console.error('Error updating conditional event:', error);
        }
    };

    return (
        <div>
            <h3>Conditional Events for Project {projectId}</h3>
            {conditionalEvents.length === 0 ? (
                <p>No conditional events found for this project.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Condition</th>
                            <th>Boolean</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conditionalEvents.map((event) => (
                            <tr key={event.id}>
                                <td>{event.id}</td>
                                <td>
                                    {editEventId === event.id ? (
                                        <input
                                            type="text"
                                            value={editEventData.condition}
                                            onChange={(e) => setEditEventData({ ...editEventData, condition: e.target.value })}
                                        />
                                    ) : (
                                        event.condition
                                    )}
                                </td>
                                <td>
                                    {editEventId === event.id ? (
                                        <input
                                            type="text"
                                            value={editEventData.boolean}
                                            onChange={(e) => setEditEventData({ ...editEventData, boolean: e.target.value })}
                                        />
                                    ) : (
                                        event.boolean.toString()
                                    )}
                                </td>
                                <td>
                                    {editEventId === event.id ? (
                                        <>
                                            <button onClick={handleUpdate}>Save</button>
                                            <button onClick={() => setEditEventId(null)}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(event)}>Edit</button>
                                            <button onClick={() => handleDelete(event.id)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <h4>Add a New Conditional Event</h4>
            <table>
                <tbody>
                    <tr>
                        <td>New</td>
                        <td>
                            <input
                                type="text"
                                name="condition"
                                value={newEvent.condition}
                                onChange={handleInputChange}
                                placeholder="Condition"
                                required
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="boolean"
                                value={newEvent.boolean}
                                onChange={handleInputChange}
                                placeholder="Boolean (true/false)"
                                required
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleAddEvent}>+ Add Conditional Event</button>
        </div>
    );
};

export default ViewConditionalEvents;