import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const ViewScheduleEvents = () => {
    const { projectId } = useParams();  // Get projectId from URL parameters
    const { user } = useContext(UserContext);  // Access the user context
    const [scheduleEvents, setScheduleEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({ start_time: '', stop_time: '' });
    const [editEventId, setEditEventId] = useState(null);
    const [editEventData, setEditEventData] = useState({ start_time: '', stop_time: '' });

    // Fetch schedule events when component mounts
    useEffect(() => {
        const fetchScheduleEvents = async () => {
            try {
                const response = await axios.post('${databaseUrl}/simulation/get_schedule_events_in_project', {
                    project_id: projectId,
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,  // Send token in headers
                    },
                });
                setScheduleEvents(response.data);
            } catch (error) {
                console.error('Error fetching schedule events:', error);
            }
        };

        if (user && projectId) {
            fetchScheduleEvents();  // Fetch schedule events when component mounts
        }
    }, [user, projectId]);

    // Handle adding a new schedule event
    const handleAddEvent = async () => {
        if (!projectId || !user?.token) {
            console.error('Missing projectId or user token');
            return;
        }

        try {
            const response = await axios.post('http://${backendUrl}:3001/simulation/create_schedule_event', {
                project_id: projectId,
                start_time: newEvent.start_time,
                stop_time: newEvent.stop_time,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setScheduleEvents([...scheduleEvents, response.data]);
            setNewEvent({ start_time: '', stop_time: '' });  // Reset the new event form
        } catch (error) {
            console.error('There was an error adding the schedule event!', error);
        }
    };

    // Handle input change for new event
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };

    // Handle event deletion
    const handleDelete = async (eventId) => {
        try {
            await axios.delete(`http://${backendUrl}:3001/simulation/delete_schedule_event/${eventId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,  // Send token in headers
                },
            });
            // Filter out the deleted event from the list
            setScheduleEvents(scheduleEvents.filter(event => event.id !== eventId));
        } catch (error) {
            console.error('Error deleting schedule event:', error);
        }
    };

    // Handle event editing
    const handleEdit = (event) => {
        setEditEventId(event.id);
        setEditEventData({
            start_time: event.start_time,
            stop_time: event.stop_time,
        });
    };

    // Handle event update submission
    const handleUpdate = async () => {
        try {
            await axios.patch(`http://${backendUrl}:3001/simulation/update_schedule_event/${editEventId}`, {
                start_time: editEventData.start_time,
                stop_time: editEventData.stop_time,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,  // Send token in headers
                },
            });
            setScheduleEvents(scheduleEvents.map(event => (
                event.id === editEventId ? { ...event, ...editEventData } : event
            )));
            setEditEventId(null);  // Reset edit mode
        } catch (error) {
            console.error('Error updating schedule event:', error);
        }
    };

    return (
        <div>
            <h3>Schedule Events for Project {projectId}</h3>
            {scheduleEvents.length === 0 ? (
                <p>No schedule events found for this project.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Start Time</th>
                            <th>Stop Time</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scheduleEvents.map((event) => (
                            <tr key={event.id}>
                                <td>{event.id}</td>
                                <td>
                                    {editEventId === event.id ? (
                                        <input
                                            type="text"
                                            value={editEventData.start_time}
                                            onChange={(e) => setEditEventData({ ...editEventData, start_time: e.target.value })}
                                        />
                                    ) : (
                                        event.start_time
                                    )}
                                </td>
                                <td>
                                    {editEventId === event.id ? (
                                        <input
                                            type="text"
                                            value={editEventData.stop_time}
                                            onChange={(e) => setEditEventData({ ...editEventData, stop_time: e.target.value })}
                                        />
                                    ) : (
                                        event.stop_time || 'N/A'
                                    )}
                                </td>
                                <td>
                                    {editEventId === event.id ? (
                                        <>
                                            <button onClick={handleUpdate}>Save</button>
                                            <button onClick={() => setEditEventId(null)}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(event)}>Edit</button>
                                            <button onClick={() => handleDelete(event.id)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <h4>Add a New Schedule Event</h4>
            <table>
                <tbody>
                    <tr>
                        <td>New</td>
                        <td>
                            <input
                                type="text"
                                name="start_time"
                                value={newEvent.start_time}
                                onChange={handleInputChange}
                                placeholder="Start Time"
                                required
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="stop_time"
                                value={newEvent.stop_time}
                                onChange={handleInputChange}
                                placeholder="Stop Time"
                                required
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <button onClick={handleAddEvent}>+ Add Schedule Event</button>
        </div>
    );
};

export default ViewScheduleEvents;