import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { UserContext } from '../contexts/UserContext';
import Logout from './LogoutForm';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const databaseUrl = process.env.REACT_APP_FLASK_URL;

const MenuBar = () => {
    const { user } = useContext(UserContext);  
    const [selectedProject, setSelectedProject] = useState(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const storedProject = sessionStorage.getItem('selectedProject');
        if (storedProject) {
            setSelectedProject(JSON.parse(storedProject));  
        }
    }, []);

    const handleProjectSelect = (projectId) => {
        sessionStorage.setItem('selectedProject', JSON.stringify({ id: projectId }));
        navigate(`/view-units/${projectId}`);
    };

    return (
        <nav style={menuBarStyle}>
            <div>
                <Link to="/" style={linkStyle}>Inputs</Link>
                {!user && (
                    <>
                        <Link to="/login" style={linkStyle}>Login</Link>
                        <Link to="/register" style={linkStyle}>Register</Link>
                    </>
                )}
                {user && (
                    <>
                        <Link to="/solve" style={linkStyle}>Solve Weibull</Link>
                        <Link to="/node-editor" style={linkStyle}>Node Editor</Link>
                        <Link to="/view-projects" style={linkStyle}>View Projects</Link> 

                        {/* Display links if a project is selected */}
                        {selectedProject && (
                            <>
                                <Link to={`/view-units/${selectedProject.id}`} style={linkStyle}>View Units</Link>
                                <Link to={`/view-parts/${selectedProject.id}`} style={linkStyle}>View Parts</Link>
                                <Link to={`/view-conditional-events/${selectedProject.id}`} style={linkStyle}>View Conditional Events</Link>
                                <Link to={`/view-schedule-events/${selectedProject.id}`} style={linkStyle}>View Schedule Events</Link>
                            </>
                        )}

                        <Link to="/create-project" style={linkStyle}>Create Project</Link>

                        {user.is_admin && (
                            <>
                                <Link to="/admin/dashboard" style={linkStyle}>Admin Dashboard</Link>
                                <Link to="/admin/assign-role" style={linkStyle}>Assign Roles</Link>
                            </>
                        )}

                        <Logout /> 
                    </>
                )}
            </div>
            <div style={{ color: '#fff' }}>
                {/* Display selected project name */}
                {selectedProject && (
                    <div style={{ marginRight: '20px' }}>
                        Selected Project: <strong>{selectedProject.name}</strong>
                    </div>
                )}
                {/* Display welcome message */}
                {user ? `Welcome, ${user.username}` : 'Please login'}
            </div>
        </nav>
    );
};

const menuBarStyle = {
    background: '#133',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const linkStyle = {
    color: '#fff',
    marginRight: '20px',
};

export default MenuBar;